.itemsBackground{
    background-color: #234B86;
    border-width: 0px;
}
.bgButton{
    background-color: #ffffff;
    border-width: 0px;
}

.btnLeft{
    left: 0;
}