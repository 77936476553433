.fillScreen {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(#001f30, #0d3e53, #001f30);
  /* background-color: #fff; */
}

.fillScreenLinks {
  width: 100vw;
  height: 100vh;
}

.zIndex-1 {
  z-index: 1;
}
.zIndex-2 {
  z-index: 5;
}
.zIndex-5 {
  z-index: 6;
}
.basketballAthlete {
  margin-left: 19vw;
  bottom: 17vh;
}
.soccerAthlete {
  margin-left: 23vw;
}
.womanAthlete {
  margin-left: 31vw;
  top: 5vh;
}

.backgroundImg {
  height: 100vh;
  width: 100vw;
}

.backgroundAnimationDiv {
  top: 0;
  left: 0;
  right: 0;
}

.menuIcons {
  transition: all 0.5s ease-in;
}

.menuIcons:hover {
  cursor: pointer;
}

.horizontalSwitch {
  display: Inline-block;
  transform: scale(-1, 1);
}

.fillHeight {
  height: 100vh;
}

.switch {
  justify-content: flex-end;
}

.switch[current-position="RIGHT"] {
  justify-content: flex-start;
}
.bannerStaterImg {
  height: 10vh;
}
