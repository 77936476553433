.dateBackgroundColor{
    background-color: #D9D9D9;
    width: 200px;
    height: 200px;
    margin-left: 1vw;
}

.lineWidth{
    width: 150px;
}

.backgroundNews{
    background-color: #D6E4FF;
    width: 100%;
}

.nameComments{
    margin-left: 10px;
}