@media only screen and (max-width: 768px) {
    .divImgSmallCard{
        width:100vw;
        overflow: hidden;
    }
    
    .containerCards{
        width: 100vw;
    }
    
    .divImgCard{
        max-width: 100vw;
        height: 50vh;
        overflow: hidden;
    }
    
    .divSmallCard{
        width: 100vw;
        overflow: hidden;
    }
    .cardImgFit{
        width: 100%;
        object-fit: cover;
        /* object-position: 0% -10vh; */
    }
    
    .cardImgFitSmalll{
        width: 100%;
        object-fit: cover;
    }
    .backgroundCover{
        width: 100vw;
        background-image: linear-gradient(to right, #001F30, #114E68, #001F30);
        border-bottom-left-radius: 20vw;
    }
    .backgroundCoverRightBorder{
        width: 100vw;
        background-image: linear-gradient(to right, #001F30, #114E68, #001F30);
    }
    .backgroundMaskRightBorder{
        width: 100vw;
        border-color: transparent;
        background-color: white;
        border-top-right-radius: 20vw;
    }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .divImgSmallCard{
        height:18vw;
        overflow: hidden;
    }
    
    .containerCards{
        width: 90vw;
    }
    
    .divImgCard{
        max-width: 90vw;
        height: 50vh;
        overflow: hidden;
    }
    
    .divSmallCard{
        width: 33%;
        overflow: hidden;
    }
    .cardImgFit{
        width: 100%;
        object-fit: cover;
        /* object-position: 0% -10vh; */
    }
    
    .cardImgFitSmalll{
        width: 100%;
        object-fit: cover;
    }
    .backgroundCover{
        width: 100vw;
        background-image: linear-gradient(to right, #001F30, #114E68, #001F30);
        border-bottom-left-radius: 15vw;
    }
    .backgroundCoverRightBorder{
        width: 100vw;
        background-image: linear-gradient(to right, #001F30, #114E68, #001F30);
    }
    .backgroundMaskRightBorder{
        width: 100vw;
        margin-top: -1px;
        border-color: transparent;
        background-color: white;
        border-top-right-radius: 15vw;
    }
}

@media only screen and (min-width: 992px) {
    .divImgSmallCard{
        height:16vh;
        overflow: hidden;
    }
    
    .containerCards{
        width: 60vw;
    }
    
    .divImgCard{
        max-width: 60vw;
        height: 55vh;
        overflow: hidden;
    }
    
    .divSmallCard{
        width: 25%;
        overflow: hidden;
    }
    .divSmallCardRelatedNews{
        width: 33%;
        overflow: hidden;
    }
    .cardImgFit{
        width: 100%;
        object-fit: cover;
        /* object-position: 0% -10vh; */
    }
    
    .cardImgFitSmalll{
        width: 100%;
        object-fit: cover;
    }
    .backgroundCover{
        width: 100vw;
        background-image: linear-gradient(to right, #001F30, #114E68, #001F30);
        border-bottom-left-radius: 20vh;
    }
    .backgroundCoverRightBorder{
        width: 100vw;
        background-image: linear-gradient(to right, #001F30, #114E68, #001F30);
    }
    .backgroundMaskRightBorder{
        width: 100vw;
        margin-top: -1px;
        border-color: transparent;
        background-color: white;
        border-top-right-radius: 20vh;
    }
}

.cardBoderCover{
    /* background-color: #076adb; */
    box-shadow: 0px 0px 10px 5px #00000050;
}
.cardBoder{
    background-color: #027093;
    box-shadow: 0px 0px 10px 5px #00000050;
}

.cardContentMainNews{
    background-color: #076adb;
}

.hrGradient{
    height: 1px;
    background-image: linear-gradient(to right, transparent, #fff, transparent);
}

.divCards{
    transition: .5s ease;
}

.divCards:hover{
    cursor: pointer;
    transform: scale(1.03);
}
