body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fontInter {
  font-family: "Inter", sans-serif;
}

.fontCinzel {
  font-family: "Cinzel", serif;
}

.fontBebasNeue {
  font-family: "Bebas Neue", cursive;
}

.fontRalewayLight {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
}

.fontRalewayRegular {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

.fontIbarraReal {
  font-family: "Ibarra Real Nova", serif;
}

.fontIbarraRealSemiBold {
  font-family: "Ibarra Real Nova", serif;
  font-weight: 600;
}
.fontIbarraRealBold {
  font-family: "Ibarra Real Nova", serif;
  font-weight: 700;
}

.textJustiify {
  text-align: justify;
}

.fullHeight {
  height: 100vh;
}

.button {
  display: inline-block;
  background-color: #0745a0;
  border: none;
  color: #ffffff;
  text-align: center;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}
.button {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.txtBtn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.button:hover .txtBtn {
  padding-right: 10px;
}
.button:hover .txtBtn:after {
  opacity: 1;
  right: 0;
}
.button .txtBtn:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.backgroundTransparent {
  background-color: transparent;
  border-width: 0px;
}
.has-drop-cap:not(:focus):first-letter {
  float: left;
  font-size: 8.4em;
  line-height: 0.68;
  font-weight: 100;
  margin: 0.05em 0.1em 0 0;
  text-transform: uppercase;
  font-style: normal;
}

.wp-block-image {
  margin-bottom: 1em;
}
.wp-block-image .alignright {
  float: right;
  margin: 0.5em 0 0.5em 1em;
}

.wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright {
  display: table;
}
.alignright {
  display: inline;
  float: right;
  margin-left: 30px;
}

/* .blocks-gallery-grid .blocks-gallery-image, .blocks-gallery-grid .blocks-gallery-item, .wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
  margin: 0 1em 1em 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: calc(50% - 1em);
} */

ul {
  list-style: none;
}

figure {
  margin: 0;
  text-align: center;
}
/* figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
} */

.wp-block-image .alignleft {
  float: left;
  margin: 0.5em 1em 0.5em 0;
}
.wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright {
  display: table;
}
.alignleft {
  display: inline;
  float: left;
  margin-right: 30px;
}
.maxPageWidth {
  width: 100vw;
  overflow-x: hidden;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
  .divContents_ img {
    margin-top: 1px;
    width: 80vw;
    height: auto;
  }
  .overflowHidden {
    overflow-x: hidden;
  }

  .overflowhiddenY {
    overflow-y: hidden;
  }

  figure video {
    width: 97vw;
  }
}
@media only screen and (min-width: 768px) {
  .divContents_ img {
    margin-top: 1px;
    width: 100%;
    height: auto;
  }
}
