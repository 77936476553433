.lineAbove {
  border-radius: 0px;
}
.btnButtons {
  transition: 0.5s ease;
}
.btnButtons:hover {
  cursor: pointer;
  background-color: rgb(3, 115, 190);
  color: white;
}

@media only screen and (max-width: 768px) {
  .divMediumImg {
    width: 103%;
    height: 25vh;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 768px) {
  .divMediumImg {
    width: 103%;
    height: 40vh;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
