.divBigImg {
  width: 100%;
  height: 51vh;
  overflow: hidden;
}
.divBigImgRight {
  width: 102%;
  height: 51vh;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.newsConainer {
  overflow: hidden;
}

.newsCategoryTitle {
  display: Inline-block;
  margin-top: -30px;
  transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
  background: -webkit-linear-gradient(transparent, transparent, #565656);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fontNewsTitleColor {
  color: #565656;
}

@media only screen and (max-width: 768px) {
  .backgroundBlueBlur {
    background-color: #002c4d;
    height: 100%;
  }
  .backgroundBlueBlurSmall {
    backdrop-filter: blur(30px);
    background-color: #002c4d99;
    height: 40%;
  }
  .divNewsCars {
    /* height: 25vh; */
    overflow: hidden;
  }
  .imgCardsNews {
    width: 100%;
    max-height: 30vh;
    object-fit: cover;
    object-position: 0% 25%;
  }
  .divSmallImg {
    width: 100%;
    height: 25vh;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 768px) {
  .backgroundBlueBlur {
    backdrop-filter: blur(30px);
    background-color: #002c4d99;
    height: 100%;
  }

  .backgroundBlueBlurSmall {
    backdrop-filter: blur(30px);
    background-color: #002c4d99;
    height: 30%;
  }

  .divNewsCars {
    height: 25vh;
    overflow: hidden;
  }

  .imgCardsNews {
    width: 105%;
    height: 25vh;
    object-fit: cover;
    object-position: 0% 25%;
  }
  .divSmallImg {
    width: 105%;
    height: 25vh;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.hrdivider {
  height: 1px;
  background-image: linear-gradient(
    to right,
    transparent,
    #949494,
    transparent
  );
}
.backgroundBlueBlurSearch {
  background-color: #002c4d;
  height: 100%;
}

.divLeft {
  margin-left: -1.5%;
}

.maxHeight {
  height: 100%;
}

.imgBigCardsNews {
  width: 100%;
  height: 51vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.imgSmallCardsNews {
  width: 100%;
  height: 25vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
