.backgroundLoadinggrow{
    background-color: #4064b0;
}

.spinner-grow-delay{
    animation-delay: 100ms;
}

.spinner-grow-delay1{
    animation-delay: 200ms;
}

.loadingContent{
    height: 50vh;
}